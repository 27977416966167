:root {
    --fc-border-color: #{$panelContentBorderColor};
}

.fc.fc-theme-standard {
    .fc-scrollgrid, td, th {
        /* border: $tableBodyCellBorder; */
    }

    .fc-list-day-cushion {
        background-color: $tableBodyRowEvenBg;
    }

    .fc-daygrid-day.fc-day-today, .fc-timegrid-col.fc-day-today {
        background-color: $tableBodyRowEvenBg;
    }

    .fc-list-event:hover td {
        background-color: $tableHeaderCellHighlightHoverBg;
    }

    .fc-daygrid-day-number {
        font-size: 1.25rem;
    }
}