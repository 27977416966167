$radBorderDividerColor: var(--surface-0);
$radBorderDividerWidth: 1px;
$radBorderDividerStyle: solid;

@mixin themed-breakpoints-classes($breakpoint) {
    .#{getBreakpointClassNameSplit('rad-border-divider-top', '', $breakpoint)} {
        border-top: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-right', '', $breakpoint)} {
        border-right: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-bottom', '', $breakpoint)} {
        border-bottom: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-left', '', $breakpoint)} {
        border-left: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-top-right', '', $breakpoint)} {
        border-top: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
        border-right: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-top-bottom', '', $breakpoint)} {
        border-top: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
        border-bottom: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-top-left', '', $breakpoint)} {
        border-top: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
        border-left: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-right-bottom', '', $breakpoint)} {
        border-right: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
        border-bottom: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-right-left', '', $breakpoint)} {
        border-right: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
        border-left: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-bottom-left', '', $breakpoint)} {
        border-bottom: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
        border-left: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-no-top', '', $breakpoint)} {
        border: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
        border-top: none;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-no-right', '', $breakpoint)} {
        border: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
        border-right: none;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-no-bottom', '', $breakpoint)} {
        border: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
        border-bottom: none;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider-no-left', '', $breakpoint)} {
        border: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
        border-left: none;
    }

    .#{getBreakpointClassNameSplit('rad-border-divider', '', $breakpoint)} {
        border: $radBorderDividerWidth $radBorderDividerStyle $radBorderDividerColor;
    }
}

@include themed-breakpoints-classes('');

@each $pre, $breakpoint in $belowBreakpoints {
    @media(max-width: $breakpoint) {
        @include themed-breakpoints-classes($pre);
    }
}

@each $pre, $breakpoint in $breakpoints {
    @media(min-width: $breakpoint) {
        @include themed-breakpoints-classes($pre);
    }
}

@include generate-color-classes('rad-text-color-ld', 'color', -1);
@include generate-color-classes('rad-background-color-ld', 'background-color', -1);

.rad-background-color-navbar {
    @extend .rad-background-color-primary-900;
}

.rad-background-color-sidebar {
    @extend .rad-background-color-primary-1000;
}

.rad-background-color-helper-topbar {
    @extend .rad-background-color-primary-1100;
}

.rad-background-color-content {
    @extend .rad-background-color-primary-1300;
}

.rad-file-dropzone {
    background: $tableHeaderBg;
    border-color: $tableHeaderBg;
}