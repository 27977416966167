/* zIndexes */
$zIndex0: 10010;
$zIndex1: 10009;
$zIndex2: 10008;
$zIndex3: 10007;
$zIndex4: 10006;
$zIndex5: 10005;
$backdropFilterBlur: 3px;

/* aspect ratios */
$aspect-ratios: (
        '1x1': 100%,
        '4x3': calc(3 / 4 * 100%),
        '16x9': calc(9 / 16 * 100%),
        '6x9': calc(6 / 9 * 100%)
);