.p-datatable {
    &.p-datatable-hoverable-rows {
        .p-datatable-tbody > tr {
            &:not(.p-highlight):hover {
                background: $tableHeaderCellHighlightHoverBg;
            }
        }
    }
}

.p-calendar {
    .p-button {
        border-color: $inputBorderColor;
        color: $textColor;
    }
}

.ql-snow {
    .ql-picker-options {
        background-color: $panelContentBg !important;
    }

    .ql-fill {
        fill: $textColor !important;
    }

    .ql-stroke {
        stroke: $textColor !important;
    }

    .ql-picker-label, .ql-picker-item {
        color: $textColor !important;
    }

    .ql-active {
        .ql-fill {
            fill: $primaryColor !important;
        }

        .ql-stroke {
            stroke: $primaryColor !important;
        }

        &.ql-picker-label {
            color: $primaryColor !important;
        }
    }
}