$primaryColor: hsl(213, 94%, 68%) !default;
$primaryLightColor: hsl(212, 96%, 78%) !default;
$primaryLighterColor: hsl(213, 97%, 87%) !default;
$primaryLightestColor: rgba(96, 165, 250, .2) !default;
$primaryTextColor: hsl(224, 71%, 4%) !default;

$highlightBg: rgba(96, 165, 250, .16) !default;
$highlightTextColor: rgba(255, 255, 255, .87) !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

//shades
$shade000: rgba(255, 255, 255, 0.87) !default; //text color
$shade100: rgba(255, 255, 255, 0.6) !default; //text secondary colornd
$shade200: hsl(220, 14%, 96%) !default; //hover background
$shade300: hsl(220, 13%, 91%) !default; //border, divider
$shade400: hsl(216, 12%, 84%) !default; //input border
$shade500: hsl(220, 9%, 46%) !default;
$shade600: hsl(214, 14%, 30%) !default; //input bg, border, divider
$shade700: hsl(217, 19%, 27%) !default; //menu bg
$shade800: hsl(215, 28%, 17%) !default; //elevated surface
$shade900: hsl(221, 39%, 11%) !default; //ground surface

$hoverBg: rgba(255, 255, 255, 0.03) !default;

//global
$fontFamily: 'Inter var', sans-serif !default;
$fontSize: 1rem !default;
$fontWeight: normal !default;
$fontWeightBold: 500 !default;
$textColor: $shade000 !default;
$textSecondaryColor: $shade100 !default;
$borderRadius: 6px !default;
$transitionDuration: 0.2s !default;
$formElementTransition: background-color $transitionDuration, color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration !default;
$actionIconTransition: background-color $transitionDuration, color $transitionDuration, box-shadow $transitionDuration !default;
$listItemTransition: box-shadow $transitionDuration !default;
$primeIconFontSize: 1rem !default;
$divider: 1px solid $shade600 !default;
$inlineSpacing: 0.5rem !default; //spacing between inline elements
$disabledOpacity: 0.4 !default; //opacity of a disabled item
$maskBg: rgba(0, 0, 0, 0.4) !default; //modal mask bg color
$loadingIconFontSize: 2rem !default;
$errorColor: hsl(0, 94%, 82%) !default;

//scale
$scaleSM: 0.875 !default;
$scaleLG: 1.25 !default;

//focus
$focusOutlineColor: $primaryLightestColor !default;
$focusOutline: 0 none !default;
$focusOutlineOffset: 0 !default;
$inputFocusOutlineOffset: $focusOutlineOffset !default;
$focusShadow: 0 0 0 0.2rem $focusOutlineColor !default;

//action icons
$actionIconWidth: 2rem !default;
$actionIconHeight: 2rem !default;
$actionIconBg: transparent !default;
$actionIconBorder: 0 none !default;
$actionIconColor: $shade100 !default;
$actionIconHoverBg: $hoverBg !default;
$actionIconHoverBorderColor: transparent !default;
$actionIconHoverColor: $shade000 !default;
$actionIconBorderRadius: 50% !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputPaddingX: 0.75rem !default;
$inputPaddingY: 0.75rem !default;
$inputPadding: $inputPaddingY $inputPaddingX !default;
$inputTextFontSize: 1rem !default;
$inputBg: $shade900 !default;
$inputTextColor: $shade000 !default;
$inputIconColor: $shade100 !default;
$inputBorderColor: $shade600;
$inputBorder: 1px solid $inputBorderColor !default;
$inputHoverBorderColor: $primaryColor !default;
$inputFocusBorderColor: $primaryColor !default;
$inputErrorBorderColor: $errorColor !default;
$inputPlaceholderTextColor: $shade100 !default;
$inputFilledBg: $shade600 !default;
$inputFilledHoverBg: $inputFilledBg !default;
$inputFilledFocusBg: $inputFilledBg !default;

//input groups
$inputGroupBg: $shade800 !default;
$inputGroupTextColor: $shade100 !default;
$inputGroupAddOnMinWidth: 3rem !default;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListBg: $shade800 !default;
$inputListTextColor: $shade000 !default;
$inputListBorder: $inputBorder !default;
$inputListPadding: 0.75rem 0 !default;
$inputListItemPadding: 0.75rem 1.25rem !default;
$inputListItemBg: transparent !default;
$inputListItemTextColor: $shade000 !default;
$inputListItemHoverBg: $hoverBg !default;
$inputListItemTextHoverColor: $shade000 !default;
$inputListItemFocusBg: $shade600 !default;
$inputListItemTextFocusColor: $shade000 !default;
$inputListItemBorder: 0 none !default;
$inputListItemBorderRadius: 0 !default;
$inputListItemMargin: 0 !default;
$inputListItemFocusShadow: inset 0 0 0 0.15rem $focusOutlineColor !default;
$inputListHeaderPadding: 0.75rem 1.25rem !default;
$inputListHeaderMargin: 0 !default;
$inputListHeaderBg: $shade800 !default;
$inputListHeaderTextColor: $shade000 !default;
$inputListHeaderBorder: 1px solid $shade600 !default;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBg: $inputListBg !default;
$inputOverlayHeaderBg: $inputListHeaderBg !default;
$inputOverlayBorder: 1px solid $shade600 !default;
$inputOverlayShadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !default;

//password
$passwordMeterBg: $shade600 !default;
$passwordWeakBg: hsl(359, 67%, 76%) !default;
$passwordMediumBg: hsl(34, 100%, 78%) !default;
$passwordStrongBg: hsl(140, 53%, 72%) !default;

//button
$buttonPadding: 0.75rem 1.25rem !default;
$buttonIconOnlyWidth: 3rem !default;
$buttonIconOnlyPadding: 0.75rem 0 !default;
$buttonBg: $primaryColor !default;
$buttonTextColor: $primaryTextColor !default;
$buttonBorder: 1px solid $primaryColor !default;
$buttonHoverBg: $primaryLightColor !default;
$buttonTextHoverColor: $primaryTextColor !default;
$buttonHoverBorderColor: $primaryLightColor !default;
$buttonActiveBg: $primaryLighterColor !default;
$buttonTextActiveColor: $primaryTextColor !default;
$buttonActiveBorderColor: $primaryLighterColor !default;
$raisedButtonShadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !default;
$roundedButtonBorderRadius: 2rem !default;

$textButtonHoverBgOpacity: 0.04 !default;
$textButtonActiveBgOpacity: 0.16 !default;
$outlinedButtonBorder: 1px solid !default;
$plainButtonTextColor: $textSecondaryColor !default;
$plainButtonHoverBgColor: $hoverBg !default;
$plainButtonActiveBgColor: rgba(255, 255, 255, 0.16) !default;

$secondaryButtonBg: hsl(215, 20%, 65%) !default;
$secondaryButtonTextColor: hsl(229, 84%, 5%) !default;
$secondaryButtonBorder: 1px solid hsl(215, 20%, 65%) !default;
$secondaryButtonHoverBg: hsl(213, 27%, 84%) !default;
$secondaryButtonTextHoverColor: $secondaryButtonTextColor !default;
$secondaryButtonHoverBorderColor: hsl(213, 27%, 84%) !default;
$secondaryButtonActiveBg: hsl(214, 32%, 91%) !default;
$secondaryButtonTextActiveColor: $secondaryButtonTextColor !default;
$secondaryButtonActiveBorderColor: hsl(214, 32%, 91%) !default;
$secondaryButtonFocusShadow: 0 0 0 1px scale-color($secondaryButtonBg, $lightness: 30%) !default;

$infoButtonBg: hsl(198, 93%, 60%) !default;
$infoButtonTextColor: hsl(204, 80%, 16%) !default;
$infoButtonBorder: 1px solid hsl(198, 93%, 60%) !default;
$infoButtonHoverBg: hsl(199, 95%, 74%) !default;
$infoButtonTextHoverColor: $infoButtonTextColor !default;
$infoButtonHoverBorderColor: hsl(199, 95%, 74%) !default;
$infoButtonActiveBg: hsl(201, 94%, 86%) !default;
$infoButtonTextActiveColor: $infoButtonTextColor !default;
$infoButtonActiveBorderColor: hsl(201, 94%, 86%) !default;
$infoButtonFocusShadow: 0 0 0 1px scale-color($infoButtonBg, $lightness: 30%) !default;

$successButtonBg: hsl(142, 69%, 58%) !default;
$successButtonTextColor: hsl(145, 80%, 10%) !default;
$successButtonBorder: 1px solid hsl(142, 69%, 58%) !default;
$successButtonHoverBg: hsl(142, 77%, 73%) !default;
$successButtonTextHoverColor: $successButtonTextColor !default;
$successButtonHoverBorderColor: hsl(142, 77%, 73%) !default;
$successButtonActiveBg: hsl(141, 79%, 85%) !default;
$successButtonTextActiveColor: $successButtonTextColor !default;
$successButtonActiveBorderColor: hsl(141, 79%, 85%) !default;
$successButtonFocusShadow: 0 0 0 1px scale-color($successButtonBg, $lightness: 30%) !default;

$warningButtonBg: hsl(27, 96%, 61%) !default;
$warningButtonTextColor: hsl(13, 81%, 15%) !default;
$warningButtonBorder: 1px solid hsl(27, 96%, 61%) !default;
$warningButtonHoverBg: hsl(31, 97%, 72%) !default;
$warningButtonTextHoverColor: $warningButtonTextColor !default;
$warningButtonHoverBorderColor: hsl(31, 97%, 72%) !default;
$warningButtonActiveBg: hsl(32, 98%, 83%) !default;
$warningButtonTextActiveColor: $warningButtonTextColor !default;
$warningButtonActiveBorderColor: hsl(32, 98%, 83%) !default;
$warningButtonFocusShadow: 0 0 0 1px scale-color($warningButtonBg, $lightness: 30%) !default;

$helpButtonBg: hsl(270, 95%, 75%) !default;
$helpButtonTextColor: hsl(274, 87%, 21%) !default;
$helpButtonBorder: 1px solid hsl(270, 95%, 75%) !default;
$helpButtonHoverBg: hsl(269, 97%, 85%) !default;
$helpButtonTextHoverColor: $helpButtonTextColor !default;
$helpButtonHoverBorderColor: hsl(269, 97%, 85%) !default;
$helpButtonActiveBg: hsl(269, 100%, 92%) !default;
$helpButtonTextActiveColor: $helpButtonTextColor !default;
$helpButtonActiveBorderColor: hsl(269, 100%, 92%) !default;
$helpButtonFocusShadow: 0 0 0 1px scale-color($helpButtonBg, $lightness: 30%) !default;

$dangerButtonBg: hsl(0, 91%, 71%) !default;
$dangerButtonTextColor: hsl(0, 75%, 15%) !default;
$dangerButtonBorder: 1px solid hsl(0, 91%, 71%) !default;
$dangerButtonHoverBg: hsl(0, 94%, 82%) !default;
$dangerButtonTextHoverColor: $dangerButtonTextColor !default;
$dangerButtonHoverBorderColor: hsl(0, 94%, 82%) !default;
$dangerButtonActiveBg: hsl(0, 96%, 89%) !default;
$dangerButtonTextActiveColor: $dangerButtonTextColor !default;
$dangerButtonActiveBorderColor: hsl(0, 96%, 89%) !default;
$dangerButtonFocusShadow: 0 0 0 1px scale-color($dangerButtonBg, $lightness: 30%) !default;

$contrastButtonBg: hsl(0, 0%, 100%) !default;
$contrastButtonTextColor: $shade900 !default;
$contrastButtonBorder: 1px solid $contrastButtonBg !default;
$contrastButtonHoverBg: hsl(220, 14%, 96%) !default;
$contrastButtonTextHoverColor: $contrastButtonTextColor !default;
$contrastButtonHoverBorderColor: hsl(220, 14%, 96%) !default;
$contrastButtonActiveBg: hsl(220, 13%, 91%) !default;
$contrastButtonTextActiveColor: $contrastButtonTextColor !default;
$contrastButtonActiveBorderColor: hsl(220, 13%, 91%) !default;
$contrastButtonFocusShadow: none !default;

$linkButtonColor: $primaryColor !default;
$linkButtonHoverColor: $primaryColor !default;
$linkButtonTextHoverDecoration: underline !default;
$linkButtonFocusShadow: 0 0 0 1px $focusOutlineColor !default;

//checkbox
$checkboxWidth: 22px !default;
$checkboxHeight: 22px !default;
$checkboxBorder: 2px solid $shade600 !default;
$checkboxIconFontSize: 14px !default;
$checkboxActiveBorderColor: $primaryColor !default;
$checkboxActiveBg: $primaryColor !default;
$checkboxIconActiveColor: $primaryTextColor !default;
$checkboxActiveHoverBg: $primaryLighterColor !default;
$checkboxIconActiveHoverColor: $primaryTextColor !default;
$checkboxActiveHoverBorderColor: $primaryLighterColor !default;

//radiobutton
$radiobuttonWidth: 22px !default;
$radiobuttonHeight: 22px !default;
$radiobuttonBorder: 2px solid $shade600 !default;
$radiobuttonIconSize: 12px !default;
$radiobuttonActiveBorderColor: $primaryColor !default;
$radiobuttonActiveBg: $primaryColor !default;
$radiobuttonIconActiveColor: $primaryTextColor !default;
$radiobuttonActiveHoverBg: $primaryLighterColor !default;
$radiobuttonIconActiveHoverColor: $primaryTextColor !default;
$radiobuttonActiveHoverBorderColor: $primaryLighterColor !default;

//colorpicker
$colorPickerPreviewWidth: 2rem !default;
$colorPickerPreviewHeight: 2rem !default;
$colorPickerBg: $shade800 !default;
$colorPickerBorder: 1px solid $shade600 !default;
$colorPickerHandleColor: $shade000 !default;

//togglebutton
$toggleButtonBg: $shade800 !default;
$toggleButtonBorder: 1px solid $shade600 !default;
$toggleButtonTextColor: $shade000 !default;
$toggleButtonIconColor: $shade100 !default;
$toggleButtonHoverBg: $hoverBg !default;
$toggleButtonHoverBorderColor: $shade600 !default;
$toggleButtonTextHoverColor: $shade000 !default;
$toggleButtonIconHoverColor: $shade100 !default;
$toggleButtonActiveBg: $primaryColor !default;
$toggleButtonActiveBorderColor: $primaryColor !default;
$toggleButtonTextActiveColor: $primaryTextColor !default;
$toggleButtonIconActiveColor: $primaryTextColor !default;
$toggleButtonActiveHoverBg: $primaryLightColor !default;
$toggleButtonActiveHoverBorderColor: $primaryLightColor !default;
$toggleButtonTextActiveHoverColor: $primaryTextColor !default;
$toggleButtonIconActiveHoverColor: $primaryTextColor !default;

//inplace
$inplacePadding: $inputPadding !default;
$inplaceHoverBg: $hoverBg !default;
$inplaceTextHoverColor: $shade000 !default;

//rating
$ratingIconFontSize: 1.143rem !default;
$ratingCancelIconColor: hsl(340, 82%, 76%) !default;
$ratingCancelIconHoverColor: hsl(340, 82%, 76%) !default;
$ratingStarIconOffColor: $shade000 !default;
$ratingStarIconOnColor: $primaryColor !default;
$ratingStarIconHoverColor: $primaryColor !default;

//slider
$sliderBg: $shade600 !default;
$sliderBorder: 0 none !default;
$sliderHorizontalHeight: 0.286rem !default;
$sliderVerticalWidth: 0.286rem !default;
$sliderHandleWidth: 1.143rem !default;
$sliderHandleHeight: 1.143rem !default;
$sliderHandleBg: $shade600 !default;
$sliderHandleBorder: 2px solid $primaryColor !default;
$sliderHandleBorderRadius: 50% !default;
$sliderHandleHoverBorderColor: $primaryColor !default;
$sliderHandleHoverBg: $primaryColor !default;
$sliderRangeBg: $primaryColor !default;

//calendar
$calendarTableMargin: 0.5rem 0 !default;
$calendarPadding: 0.5rem !default;
$calendarBg: $shade800 !default;
$calendarInlineBg: $calendarBg !default;
$calendarTextColor: $shade000 !default;
$calendarBorder: $inputListBorder !default;
$calendarOverlayBorder: $inputOverlayBorder !default;

$calendarHeaderPadding: 0.5rem !default;
$calendarHeaderBg: $shade800 !default;
$calendarInlineHeaderBg: $calendarBg !default;
$calendarHeaderBorder: 1px solid $shade600 !default;
$calendarHeaderTextColor: $shade000 !default;
$calendarHeaderFontWeight: $fontWeightBold !default;
$calendarHeaderCellPadding: 0.5rem !default;
$calendarMonthYearHeaderHoverTextColor: $primaryColor !default;

$calendarCellDatePadding: 0.5rem !default;
$calendarCellDateWidth: 2.5rem !default;
$calendarCellDateHeight: 2.5rem !default;
$calendarCellDateBorderRadius: 50% !default;
$calendarCellDateBorder: 1px solid transparent !default;
$calendarCellDateHoverBg: $hoverBg !default;
$calendarCellDateTodayBg: transparent !default;
$calendarCellDateTodayBorderColor: transparent !default;
$calendarCellDateTodayTextColor: $primaryColor !default;

$calendarButtonBarPadding: 1rem 0 !default;
$calendarTimePickerPadding: 0.5rem !default;
$calendarTimePickerElementPadding: 0 0.5rem !default;
$calendarTimePickerTimeFontSize: 1.25rem !default;

$calendarBreakpoint: 769px !default;
$calendarCellDatePaddingSM: 0 !default;

//input switch
$inputSwitchWidth: 3rem !default;
$inputSwitchHeight: 1.75rem !default;
$inputSwitchBorderRadius: 30px !default;
$inputSwitchHandleWidth: 1.25rem !default;
$inputSwitchHandleHeight: 1.25rem !default;
$inputSwitchHandleBorderRadius: 50% !default;
$inputSwitchSliderPadding: 0.25rem !default;
$inputSwitchSliderOffBg: $shade500 !default;
$inputSwitchHandleOffBg: $shade100 !default;
$inputSwitchSliderOffHoverBg: $shade600 !default;
$inputSwitchSliderOnBg: $primaryColor !default;
$inputSwitchSliderOnHoverBg: $primaryLightColor !default;
$inputSwitchHandleOnBg: $primaryTextColor !default;

//panel
$panelHeaderBorderColor: $shade600 !default;
$panelHeaderBorder: 1px solid $panelHeaderBorderColor !default;
$panelHeaderBg: var(--primary-1200) !default; // $shade800 !default;
$panelHeaderTextColor: $shade000 !default;
$panelHeaderFontWeight: $fontWeightBold !default;
$panelHeaderPadding: 1.25rem !default;
$panelToggleableHeaderPadding: 0.75rem 1.25rem !default;

$panelHeaderHoverBg: $hoverBg !default;
$panelHeaderHoverBorderColor: $shade600 !default;
$panelHeaderTextHoverColor: $shade000 !default;

$panelContentBorderColor: $shade600 !default;
$panelContentBorder: 1px solid $panelContentBorderColor !default;
$panelContentBg: var(--primary-1200) !default; // $shade800 !default;
$panelContentEvenRowBg: hsl(215, 28%, 15%) !default;
$panelContentTextColor: $shade000 !default;
$panelContentPadding: 1.25rem !default;

$panelFooterBorderColor: $shade600 !default;
$panelFooterBorder: 1px solid $panelFooterBorderColor !default;
$panelFooterBg: $shade800 !default;
$panelFooterTextColor: $shade000 !default;
$panelFooterPadding: 0.75rem 1.25rem !default;

//accordion
$accordionSpacing: 4px !default;
$accordionHeaderBorder: $panelHeaderBorder !default;
$accordionHeaderBg: $panelHeaderBg !default;
$accordionHeaderTextColor: $panelHeaderTextColor !default;
$accordionHeaderFontWeight: $panelHeaderFontWeight !default;
$accordionHeaderPadding: $panelHeaderPadding !default;

$accordionHeaderHoverBg: $hoverBg !default;
$accordionHeaderHoverBorderColor: $shade600 !default;
$accordionHeaderTextHoverColor: $shade000 !default;

$accordionHeaderActiveBg: $panelHeaderBg !default;
$accordionHeaderActiveBorderColor: $shade600 !default;
$accordionHeaderTextActiveColor: $shade000 !default;

$accordionHeaderActiveHoverBg: $hoverBg !default;
$accordionHeaderActiveHoverBorderColor: $shade600 !default;
$accordionHeaderTextActiveHoverColor: $shade000 !default;

$accordionContentBorder: $panelContentBorder !default;
$accordionContentBg: $panelContentBg !default;
$accordionContentTextColor: $panelContentTextColor !default;
$accordionContentPadding: $panelContentPadding !default;

//tabview
$tabviewNavBorder: 1px solid $shade600 !default;
$tabviewNavBorderWidth: 0 0 2px 0 !default;
$tabviewNavBg: transparent !default;

$tabviewHeaderSpacing: 0 !default;
$tabviewHeaderBorder: solid $shade600 !default;
$tabviewHeaderBorderWidth: 0 0 2px 0 !default;
$tabviewHeaderBorderColor: transparent transparent $shade600 transparent !default;
$tabviewHeaderBg: $shade800 !default;
$tabviewHeaderTextColor: $shade100 !default;
$tabviewHeaderFontWeight: $panelHeaderFontWeight !default;
$tabviewHeaderPadding: $panelHeaderPadding !default;
$tabviewHeaderMargin: 0 0 -2px 0 !default;

$tabviewHeaderHoverBg: $shade800 !default;
$tabviewHeaderHoverBorderColor: $primaryColor !default;
$tabviewHeaderTextHoverColor: $shade000 !default;

$tabviewHeaderActiveBg: $shade800 !default;
$tabviewHeaderActiveBorderColor: $primaryColor !default;
$tabviewHeaderTextActiveColor: $primaryColor !default;

$tabviewContentBorder: 0 none !default;
$tabviewContentBg: $shade800 !default;
$tabviewContentTextColor: $shade000 !default;
$tabviewContentPadding: $panelContentPadding !default;

//upload
$fileUploadProgressBarHeight: 0.25rem !default;
$fileUploadContentPadding: 2rem 1rem !default;
$fileUploadContentHoverBorder: 1px dashed $primaryColor !default;

//scrollpanel
$scrollPanelTrackBorder: 0 none !default;
$scrollPanelTrackBg: $shade600 !default;

//card
$cardBodyPadding: 1.25rem !default;
$cardTitleFontSize: 1.5rem !default;
$cardTitleFontWeight: $fontWeightBold !default;
$cardSubTitleFontWeight: 400 !default;
$cardSubTitleColor: $shade100 !default;
$cardContentPadding: 1.25rem 0 !default;
$cardFooterPadding: 1.25rem 0 0 0 !default;
$cardShadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !default;

//editor
$editorToolbarBg: $panelHeaderBg !default;
$editorToolbarBorder: $panelHeaderBorder !default;
$editorToolbarPadding: $panelHeaderPadding !default;
$editorToolbarIconColor: $textSecondaryColor !default;
$editorToolbarIconHoverColor: $textColor !default;
$editorIconActiveColor: $primaryColor !default;
$editorContentBorder: $panelContentBorder !default;
$editorContentBg: $panelContentBg !default;

//paginator
$paginatorBg: transparentize($shade800, 1) !default; // $shade800
$paginatorTextColor: $shade100 !default;
$paginatorBorder: solid transparentize($shade600, 1) !default; // $shade600
$paginatorBorderWidth: 1px !default;
$paginatorPadding: 0.5rem 1rem !default;
$paginatorElementWidth: $buttonIconOnlyWidth !default;
$paginatorElementHeight: $buttonIconOnlyWidth !default;
$paginatorElementBg: transparent !default;
$paginatorElementBorder: 0 none !default;
$paginatorElementIconColor: $shade100 !default;
$paginatorElementHoverBg: $hoverBg !default;
$paginatorElementHoverBorderColor: transparent !default;
$paginatorElementIconHoverColor: $shade000 !default;
$paginatorElementBorderRadius: 50% !default; //$borderRadius
$paginatorElementMargin: 0.143rem !default;
$paginatorElementPadding: 0 !default;

//table
$tableHeaderBorder: 1px solid $shade600 !default;
$tableHeaderBorderWidth: 0 0 1px 0 !default;
$tableHeaderBg: $shade800 !default;
$tableHeaderTextColor: $shade100 !default;
$tableHeaderFontWeight: $fontWeightBold !default;
$tableHeaderPadding: 1rem 1rem !default;

$tableHeaderCellPadding: 1rem 1rem !default;
$tableHeaderCellBg: transparentize($shade800, 0.5) !default; // $shade800
$tableHeaderCellTextColor: $shade000 !default;
$tableHeaderCellFontWeight: $fontWeightBold !default;
$tableHeaderCellBorder: 1px solid $shade600 !default;
$tableHeaderCellBorderWidth: 0 0 1px 0 !default;
$tableHeaderCellHoverBg: transparentize($shade800, 0.8) !default; // $hoverBg
$tableHeaderCellTextHoverColor: $shade000 !default;
$tableHeaderCellIconColor: $shade100 !default;
$tableHeaderCellIconHoverColor: $shade000 !default;
$tableHeaderCellHighlightBg: $highlightBg !default;
$tableHeaderCellHighlightTextColor: $highlightTextColor !default;
$tableHeaderCellHighlightHoverBg: $highlightBg !default;
$tableHeaderCellHighlightTextHoverColor: $highlightTextColor !default;
$tableSortableColumnBadgeSize: 1.143rem !default;

$tableBodyRowBg: transparentize($shade800, 1) !default; // $shade800
$tableBodyRowTextColor: $tableHeaderCellTextColor !default; // $shade000
$tableBodyRowEvenBg: var(--primary-900); // hsl(215, 28%, 15%) !default;
$tableBodyRowHoverBg: transparentize($shade800, 0.8) !default; // $hoverBg
$tableBodyRowTextHoverColor: $shade000 !default;
$tableBodyCellBorder: 1px solid $shade600 !default;
$tableBodyCellBorderWidth: 0 0 1px 0 !default;
$tableBodyCellPadding: 1rem 1rem !default;

$tableFooterCellPadding: 1rem 1rem !default;
$tableFooterCellBg: $shade800 !default;
$tableFooterCellTextColor: $shade000 !default;
$tableFooterCellFontWeight: $fontWeightBold !default;
$tableFooterCellBorder: 1px solid $shade600 !default;
$tableFooterCellBorderWidth: 0 0 1px 0 !default;
$tableResizerHelperBg: $primaryColor !default;

$tableFooterBorder: 1px solid $shade600 !default;
$tableFooterBorderWidth: 0 0 1px 0 !default;
$tableFooterBg: $shade800 !default;
$tableFooterTextColor: $shade000 !default;
$tableFooterFontWeight: $fontWeightBold !default;
$tableFooterPadding: 1rem 1rem !default;

$tableCellContentAlignment: left !default;
$tableTopPaginatorBorderWidth: 1px 0 1px 0 !default;
$tableBottomPaginatorBorderWidth: 0 0 1px 0 !default;

$tableScaleSM: 0.5 !default;
$tableScaleLG: 1.25 !default;

//dataview
$dataViewContentPadding: 0 !default;
$dataViewContentBorder: 0 none !default;

//tree
$treeContainerPadding: 0.286rem !default;
$treeNodePadding: 0.143rem !default;
$treeNodeContentPadding: 0.5rem !default;
$treeNodeChildrenPadding: 0 0 0 1rem !default;
$treeNodeIconColor: $shade100 !default;

//timeline
$timelineVerticalEventContentPadding: 0 1rem !default;
$timelineHorizontalEventContentPadding: 1rem 0 !default;
$timelineEventMarkerWidth: 1rem !default;
$timelineEventMarkerHeight: 1rem !default;
$timelineEventMarkerBorderRadius: 50% !default;
$timelineEventMarkerBorder: 2px solid $primaryColor !default;
$timelineEventMarkerBackground: $primaryTextColor !default;
$timelineEventConnectorSize: 2px !default;
$timelineEventColor: $shade600 !default;

//org chart
$organizationChartConnectorColor: $shade600 !default;

//message
$messageMargin: 1rem 0 !default;
$messagePadding: 1.25rem 1.75rem !default;
$messageBorderWidth: 0 0 0 6px !default;
$messageIconFontSize: 1.5rem !default;
$messageTextFontSize: 1rem !default;
$messageTextFontWeight: 500 !default;

//inline message
$inlineMessagePadding: $inputPadding !default;
$inlineMessageMargin: 0 !default;
$inlineMessageIconFontSize: 1rem !default;
$inlineMessageTextFontSize: 1rem !default;
$inlineMessageBorderWidth: 1px !default;

//toast
$toastIconFontSize: 2rem !default;
$toastMessageTextMargin: 0 0 0 1rem !default;
$toastMargin: 0 0 1rem 0 !default;
$toastPadding: 1rem !default;
$toastBorderWidth: 0 0 0 6px !default;
$toastShadow: none !default;
$toastOpacity: 1 !default;
$toastTitleFontWeight: $fontWeightBold !default;
$toastDetailMargin: $inlineSpacing 0 0 0 !default;

//severities
$infoMessageBg: rgba(59, 130, 246, 0.2) !default;
$infoMessageBorder: solid hsl(217, 91%, 60%) !default;
$infoMessageTextColor: hsl(212, 96%, 78%) !default;
$infoMessageIconColor: hsl(212, 96%, 78%) !default;
$successMessageBg: rgba(16, 185, 129, 0.2) !default;
$successMessageBorder: solid hsl(160, 84%, 39%) !default;
$successMessageTextColor: hsl(156, 72%, 67%) !default;
$successMessageIconColor: hsl(156, 72%, 67%) !default;
$warningMessageBg: rgba(234, 179, 8, 0.2) !default;
$warningMessageBorder: solid hsl(45, 93%, 47%) !default;
$warningMessageTextColor: hsl(50, 98%, 64%) !default;
$warningMessageIconColor: hsl(50, 98%, 64%) !default;
$errorMessageBg: rgba(239, 68, 68, 0.2) !default;
$errorMessageBorder: solid hsl(0, 84%, 60%) !default;
$errorMessageTextColor: hsl(0, 94%, 82%) !default;
$errorMessageIconColor: hsl(0, 94%, 82%) !default;

$secondaryMessageBg: $secondaryButtonBg !default;
$secondaryMessageBorder: solid $secondaryButtonBg !default;
$secondaryMessageTextColor: $secondaryButtonTextColor !default;
$secondaryMessageIconColor: $secondaryButtonTextColor !default;
$contrastMessageBg: $contrastButtonBg !default;
$contrastMessageBorder: solid $contrastButtonBg !default;
$contrastMessageTextColor: $contrastButtonTextColor !default;
$contrastMessageIconColor: $contrastButtonTextColor !default;

//overlays
$overlayContentBorder: 1px solid $shade600 !default;
$overlayContentBg: $panelContentBg !default;
$overlayContainerShadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12) !default;

//dialog
$dialogHeaderBg: $panelHeaderBg !default; // $shade800 !default;
$dialogHeaderBorder: 0 none !default;
$dialogHeaderTextColor: $shade000 !default;
$dialogHeaderFontWeight: $fontWeightBold !default;
$dialogHeaderFontSize: 1.25rem !default;
$dialogHeaderPadding: 1.5rem !default;
$dialogContentPadding: 0 1.5rem 2rem 1.5rem !default;
$dialogFooterBorder: 0 none !default;
$dialogFooterPadding: 0 1.5rem 1.5rem 1.5rem !default;

//confirmpopup
$confirmPopupContentPadding: $panelContentPadding !default;
$confirmPopupFooterPadding: 0 1.25rem 1.25rem 1.25rem !default;

//tooltip
$tooltipBg: $shade900 !default; // $shade600 !default;
$tooltipTextColor: $shade000 !default;
$tooltipPadding: $inputPadding !default;

//steps
$stepsItemBg: transparent !default;
$stepsItemBorder: 0 none !default;
$stepsItemTextColor: $shade100 !default;
$stepsItemNumberWidth: 2rem !default;
$stepsItemNumberHeight: 2rem !default;
$stepsItemNumberFontSize: 1.143rem !default;
$stepsItemNumberColor: $shade000 !default;
$stepsItemNumberBorderRadius: 50% !default;
$stepsItemActiveFontWeight: $fontWeightBold !default;

//progressbar, progressspinner
$progressBarHeight: 1.5rem !default;
$progressBarBorder: 0 none !default;
$progressBarBg: $shade600 !default;
$progressBarValueBg: $primaryColor !default;
$progressBarValueTextColor: $primaryTextColor !default;

//menu (e.g. menu, menubar, tieredmenu)
$menuWidth: 12.5rem !default;
$menuBg: $shade700 !default;
$menuBorder: 1px solid $shade600 !default;
$menuTextColor: $shade000 !default;
$menuitemPadding: 0.75rem 1.25rem !default;
$menuitemBorderRadius: 0 !default;
$menuitemTextColor: $shade000 !default;
$menuitemIconColor: $shade100 !default;
$menuitemTextHoverColor: $shade000 !default;
$menuitemIconHoverColor: $shade000 !default;
$menuitemHoverBg: $hoverBg !default;
$menuitemTextFocusColor: $shade000 !default;
$menuitemIconFocusColor: $shade000 !default;
$menuitemFocusBg: $shade600 !default;
$menuitemTextActiveColor: $highlightTextColor !default;
$menuitemIconActiveColor: $highlightTextColor !default;
$menuitemActiveBg: $highlightBg !default;
$menuitemActiveFocusBg: $highlightFocusBg !default;
$menuitemSubmenuIconFontSize: 0.875rem !default;
$submenuHeaderMargin: 0 !default;
$submenuHeaderPadding: 0.75rem 1.25rem !default;
$submenuHeaderBg: $shade700 !default;
$submenuHeaderTextColor: $shade000 !default;
$submenuHeaderBorderRadius: 0 !default;
$submenuHeaderFontWeight: $fontWeightBold !default;
$overlayMenuBg: $menuBg !default;
$overlayMenuBorder: 1px solid $shade600 !default;
$overlayMenuShadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !default;
$verticalMenuPadding: 0.25rem 0 !default;
$verticalMenuitemMargin: 0 !default;
$menuSeparatorMargin: 0.25rem 0 !default;

$breadcrumbPadding: 1rem !default;
$breadcrumbBg: $menuBg !default;
$breadcrumbBorder: $menuBorder !default;
$breadcrumbItemTextColor: $menuitemTextColor !default;
$breadcrumbItemIconColor: $menuitemIconColor !default;
$breadcrumbLastItemTextColor: $menuitemTextColor !default;
$breadcrumbLastItemIconColor: $menuitemIconColor !default;
$breadcrumbSeparatorColor: $menuitemTextColor !default;

$horizontalMenuPadding: 0.5rem !default;
$horizontalMenuBg: $shade700 !default;
$horizontalMenuBorder: $menuBorder !default;
$horizontalMenuTextColor: $menuTextColor !default;
$horizontalMenuRootMenuitemPadding: $menuitemPadding !default;
$horizontalMenuRootMenuitemBorderRadius: $borderRadius !default;
$horizontalMenuRootMenuitemTextColor: $menuitemTextColor !default;
$horizontalMenuRootMenuitemIconColor: $menuitemIconColor !default;
$horizontalMenuRootMenuitemTextHoverColor: $menuitemTextHoverColor !default;
$horizontalMenuRootMenuitemIconHoverColor: $menuitemIconHoverColor !default;
$horizontalMenuRootMenuitemHoverBg: $menuitemHoverBg !default;
$horizontalMenuRootMenuitemTextActiveColor: $menuitemTextActiveColor !default;
$horizontalMenuRootMenuitemIconActiveColor: $menuitemIconActiveColor !default;
$horizontalMenuRootMenuitemActiveBg: $menuitemActiveBg !default;

//badge and tag
$badgeBg: $primaryColor !default;
$badgeTextColor: $primaryTextColor !default;
$badgeMinWidth: 1.5rem !default;
$badgeHeight: 1.5rem !default;
$badgeFontWeight: $fontWeightBold !default;
$badgeFontSize: 0.75rem !default;

$tagPaddingY: 0.25rem;
$tagPaddingX: 0.4rem;
$tagPadding: $tagPaddingY $tagPaddingX !default;

//carousel
$carouselIndicatorsPadding: 1rem !default;
$carouselIndicatorBg: $shade600 !default;
$carouselIndicatorHoverBg: $hoverBg !default;
$carouselIndicatorBorderRadius: 0 !default;
$carouselIndicatorWidth: 2rem !default;
$carouselIndicatorHeight: 0.5rem !default;

//galleria
$galleriaMaskBg: rgba(0, 0, 0, 0.9) !default;
$galleriaCloseIconMargin: 0.5rem !default;
$galleriaCloseIconFontSize: 2rem !default;
$galleriaCloseIconBg: transparent !default;
$galleriaCloseIconColor: hsl(210, 17%, 98%) !default;
$galleriaCloseIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaCloseIconHoverColor: hsl(210, 17%, 98%) !default;
$galleriaCloseIconWidth: 4rem !default;
$galleriaCloseIconHeight: 4rem !default;
$galleriaCloseIconBorderRadius: 50% !default;

$galleriaItemNavigatorBg: transparent !default;
$galleriaItemNavigatorColor: hsl(210, 17%, 98%) !default;
$galleriaItemNavigatorMargin: 0 0.5rem !default;
$galleriaItemNavigatorFontSize: 2rem !default;
$galleriaItemNavigatorHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaItemNavigatorHoverColor: hsl(210, 17%, 98%) !default;
$galleriaItemNavigatorWidth: 4rem !default;
$galleriaItemNavigatorHeight: 4rem !default;
$galleriaItemNavigatorBorderRadius: $borderRadius !default;

$galleriaCaptionBg: rgba(0, 0, 0, 0.5) !default;
$galleriaCaptionTextColor: hsl(210, 17%, 98%) !default;
$galleriaCaptionPadding: 1rem !default;

$galleriaIndicatorsPadding: 1rem !default;
$galleriaIndicatorBg: $shade600 !default;
$galleriaIndicatorHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaIndicatorBorderRadius: 50% !default;
$galleriaIndicatorWidth: 1rem !default;
$galleriaIndicatorHeight: 1rem !default;
$galleriaIndicatorsBgOnItem: rgba(0, 0, 0, 0.5) !default;
$galleriaIndicatorBgOnItem: rgba(255, 255, 255, 0.4) !default;
$galleriaIndicatorHoverBgOnItem: rgba(255, 255, 255, 0.6) !default;

$galleriaThumbnailContainerBg: rgba(0, 0, 0, 0.9) !default;
$galleriaThumbnailContainerPadding: 1rem 0.25rem !default;
$galleriaThumbnailNavigatorBg: transparent !default;
$galleriaThumbnailNavigatorColor: hsl(210, 17%, 98%) !default;
$galleriaThumbnailNavigatorHoverBg: rgba(255, 255, 255, 0.1) !default;
$galleriaThumbnailNavigatorHoverColor: hsl(210, 17%, 98%) !default;
$galleriaThumbnailNavigatorBorderRadius: 50% !default;
$galleriaThumbnailNavigatorWidth: 2rem !default;
$galleriaThumbnailNavigatorHeight: 2rem !default;

//divider
$dividerHorizontalMargin: 1.25rem 0 !default;
$dividerHorizontalPadding: 0 1.25rem !default;
$dividerVerticalMargin: 0 1.25rem !default;
$dividerVerticalPadding: 1.25rem 0 !default;
$dividerSize: 1px !default;
$dividerColor: $shade600 !default;

//avatar
$avatarBg: $shade600 !default;
$avatarTextColor: $textColor !default;

//chip
$chipBg: $shade600 !default;
$chipTextColor: $textColor !default;
$chipBorderRadius: 16px !default;
$chipFocusBg: $shade500 !default;
$chipFocusTextColor: $textColor !default;

//scrollTop
$scrollTopBg: $highlightBg !default;
$scrollTopHoverBg: scale-color($highlightBg, $alpha: 24%) !default;
$scrollTopWidth: 3rem !default;
$scrollTopHeight: 3rem !default;
$scrollTopBorderRadius: 50% !default;
$scrollTopFontSize: 1.5rem !default;
$scrollTopTextColor: $highlightTextColor !default;

//skeleton
$skeletonBg: rgba(255, 255, 255, 0.06) !default;
$skeletonAnimationBg: rgba(255, 255, 255, 0.04) !default;

//splitter
$splitterGutterBg: rgba(255, 255, 255, 0.03) !default;
$splitterGutterHandleBg: $shade600 !default;

//speeddial
$speedDialButtonWidth: 4rem !default;
$speedDialButtonHeight: 4rem !default;
$speedDialButtonIconFontSize: 1.3rem !default;
$speedDialActionWidth: 3rem !default;
$speedDialActionHeight: 3rem !default;
$speedDialActionBg: $shade000 !default;
$speedDialActionHoverBg: $shade100 !default;
$speedDialActionTextColor: $shade900 !default;
$speedDialActionTextHoverColor: $shade900 !default;

//dock
$dockActionWidth: 4rem !default;
$dockActionHeight: 4rem !default;
$dockItemPadding: 0.5rem !default;
$dockItemBorderRadius: $borderRadius !default;
$dockCurrentItemMargin: 1.5rem !default;
$dockFirstItemsMargin: 1.3rem !default;
$dockSecondItemsMargin: 0.9rem !default;
$dockBg: rgba(255, 255, 255, 0.1) !default;
$dockBorder: 1px solid rgba(255, 255, 255, 0.2) !default;
$dockPadding: 0.5rem 0.5rem !default;
$dockBorderRadius: 0.5rem !default;

//image
$imageMaskBg: rgba(0, 0, 0, 0.9) !default;
$imagePreviewToolbarPadding: 1rem !default;
$imagePreviewIndicatorColor: hsl(210, 17%, 98%) !default;
$imagePreviewIndicatorBg: rgba(0, 0, 0, 0.5) !default;
$imagePreviewActionIconBg: transparent !default;
$imagePreviewActionIconColor: hsl(210, 17%, 98%) !default;
$imagePreviewActionIconHoverBg: rgba(255, 255, 255, 0.1) !default;
$imagePreviewActionIconHoverColor: hsl(210, 17%, 98%) !default;
$imagePreviewActionIconWidth: 3rem !default;
$imagePreviewActionIconHeight: 3rem !default;
$imagePreviewActionIconFontSize: 1.5rem !default;
$imagePreviewActionIconBorderRadius: 50% !default;

:root {
    font-family: 'Inter var', sans-serif;
    font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11';
    font-variation-settings: normal;
    --font-family: 'Inter var', sans-serif;
    --font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11';
    --surface-a: #{$shade800};
    --surface-b: #{$shade900};
    --surface-c: #{$hoverBg};
    --surface-d: #{$shade600};
    --surface-e: #{$shade800};
    --surface-f: #{$shade800};
    --text-color: #{$shade000};
    --text-color-secondary: #{$shade100};
    --primary-color: #{$primaryColor};
    --primary-color-text: #{$primaryTextColor};
    --surface-0: hsl(221, 39%, 11%);
    --surface-50: hsl(215, 28%, 17%);
    --surface-100: hsl(217, 19%, 27%);
    --surface-200: hsl(215, 14%, 34%);
    --surface-300: hsl(220, 9%, 46%);
    --surface-400: hsl(218, 11%, 65%);
    --surface-500: hsl(216, 12%, 84%);
    --surface-600: hsl(220, 13%, 91%);
    --surface-700: hsl(220, 14%, 96%);
    --surface-800: hsl(210, 20%, 98%);
    --surface-900: hsl(0, 0%, 100%);
    --gray-50: hsl(210, 20%, 98%);
    --gray-100: hsl(220, 14%, 96%);
    --gray-200: hsl(220, 13%, 91%);
    --gray-300: hsl(216, 12%, 84%);
    --gray-400: hsl(218, 11%, 65%);
    --gray-500: hsl(220, 9%, 46%);
    --gray-600: hsl(215, 14%, 34%);
    --gray-700: hsl(217, 19%, 27%);
    --gray-800: hsl(215, 28%, 17%);
    --gray-900: hsl(221, 39%, 11%);
    --content-padding: #{$panelContentPadding};
    --inline-spacing: #{$inlineSpacing};
    --border-radius: #{$borderRadius};
    --surface-ground: hsl(221, 39%, 11%);
    --surface-section: hsl(221, 39%, 11%);
    --surface-card: hsl(215, 28%, 17%);
    --surface-overlay: hsl(215, 28%, 17%);
    --surface-border: rgba(255, 255, 255, 0.1);
    --surface-hover: rgba(255, 255, 255, 0.03);
    --focus-ring: #{$focusShadow};
    --maskbg: #{$maskBg};
    --highlight-bg: #{$highlightBg};
    --highlight-text-color: #{$highlightTextColor};
    color-scheme: dark;
}