@import 'base/mixins';

.rad-text-normal {
    font-size: $fontSize;
}

.rad-text-small {
    @include scaledFontSize($fontSize, $scaleSM);
}

.rad-text-large {
    @include scaledFontSize($fontSize, $scaleLG);
}

.rad-text-label {
    @extend .rad-text-small;
    @extend .rad-text-color-label;
}

.rad-text-bold {
    font-weight: $fontWeightBold;
}

.rad-text-normal-bold {
    @extend .rad-text-normal;
    @extend .rad-text-bold;
}

.rad-text-small-bold {
    @extend .rad-text-small;
    @extend .rad-text-bold;
}

.rad-text-large-bold {
    @extend .rad-text-large;
    @extend .rad-text-bold;
}

.rad-text-label-bold {
    @extend .rad-text-label;
    @extend .rad-text-bold;
}

.rad-text-color-label {
    color: $inputPlaceholderTextColor;
}

.rad-text-color-shade-000 {
    color: $shade000;
}

.rad-text-color-shade-100 {
    color: $shade100;
}

.rad-text-color-shade-200 {
    color: $shade200;
}

.rad-text-color-shade-300 {
    color: $shade300;
}

.rad-text-color-shade-400 {
    color: $shade400;
}

.rad-text-color-primary {
    color: $buttonBg;
}

.rad-text-color-success {
    color: $successButtonBg;
}

.rad-text-color-danger {
    color: $dangerButtonBg;
}

@include generate-color-classes('rad-text-color', 'color', 1);