@mixin focused-ring($ring-color) {
    box-shadow: 0 0 0 2px #1c2127, 0 0 0 4px $ring-color,
        0 1px 2px 0 rgba(0, 0, 0, 0);
}

@layer primeng {
    .p-button-label {
        font-weight: 700;
    }

    .p-selectbutton > .p-button,
    .p-togglebutton.p-button {
        transition: background-color $transitionDuration,
            border-color $transitionDuration, box-shadow $transitionDuration;
    }

    .p-accordion {
        .p-accordion-header {
            .p-accordion-header-link {
                transition: background-color $transitionDuration,
                    border-color $transitionDuration,
                    box-shadow $transitionDuration;
            }
        }
    }

    .p-tabview {
        .p-tabview-nav {
            li {
                .p-tabview-nav-link {
                    transition: background-color $transitionDuration,
                        border-color $transitionDuration,
                        box-shadow $transitionDuration;
                }
            }
        }
    }

    .p-tabmenu {
        .p-tabmenu-nav {
            .p-tabmenuitem {
                .p-menuitem-link {
                    transition: background-color $transitionDuration,
                        border-color $transitionDuration,
                        box-shadow $transitionDuration;
                }
            }
        }
    }

    .p-carousel {
        .p-carousel-indicators .p-carousel-indicator.p-highlight button {
            background-color: $primaryColor;
        }
    }

    .p-galleria {
        .p-galleria-indicators .p-galleria-indicator.p-highlight button {
            background-color: $primaryColor;
        }
    }

    .p-button {
        &:focus {
            @include focused-ring(rgba($buttonBg, 0.7));
        }

        &.p-button-secondary:enabled:focus {
            @include focused-ring(rgba($secondaryButtonBg, 0.7));
        }

        &.p-button-success:enabled:focus {
            @include focused-ring(rgba($successButtonBg, 0.7));
        }

        &.p-button-info:enabled:focus {
            @include focused-ring(rgba($infoButtonBg, 0.7));
        }

        &.p-button-warning:enabled:focus {
            @include focused-ring(rgba($warningButtonBg, 0.7));
        }

        &.p-button-help:enabled:focus {
            @include focused-ring(rgba($helpButtonBg, 0.7));
        }

        &.p-button-danger:enabled:focus {
            @include focused-ring(rgba($dangerButtonBg, 0.7));
        }
    }

    .p-datatable {
        .p-datatable-tbody {
            > tr {
                &.p-datatable-dragpoint-top > td {
                    box-shadow: inset 0 2px 0 0 $primaryColor;
                }

                &.p-datatable-dragpoint-bottom > td {
                    box-shadow: inset 0 -2px 0 0 $primaryColor;
                }
            }
        }
    }

    .p-datatable {
        .p-datatable-tbody {
            > tr {
                &.p-datatable-dragpoint-top > td {
                    box-shadow: inset 0 2px 0 0 $primaryColor;
                }

                &.p-datatable-dragpoint-bottom > td {
                    box-shadow: inset 0 -2px 0 0 $primaryColor;
                }
            }
        }
    }

    .p-speeddial-item {
        &.p-focus > .p-speeddial-action {
            @include focused-ring(rgba($buttonBg, 0.7));
        }
    }

    .p-toast-message {
        backdrop-filter: blur(10px);
    }

    .p-message {
        .p-message-close {
            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }
        }
    }

    .p-toast {
        .p-toast-message {
            .p-toast-icon-close {
                &:hover {
                    background: rgba(255, 255, 255, 0.1);
                }
            }
        }
    }

    .p-inline-message-text {
        font-weight: 500;
    }

    .p-picklist-buttons .p-button,
    .p-orderlist-controls .p-button {
        transition: opacity $transitionDuration,
            background-color $transitionDuration, color $transitionDuration,
            border-color $transitionDuration, box-shadow $transitionDuration;
    }

    .p-steps {
        .p-steps-item {
            &.p-highlight {
                .p-steps-number {
                    background: $primaryColor;
                    color: $primaryTextColor;
                }
            }
        }
    }
}
