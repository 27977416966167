@import "primeicons/primeicons.css";
@import 'themes/overrides/dark-colors';
@import 'themes/primeng-sass-theme/themes/lara/lara-dark/blue/theme';
@import 'main-styles/extensions/extension-primeng';
@import 'themed-styles/extensions/extension-primeng-dark';
@import 'base/typography';
@import 'variables/full-calendar';
@import 'main-styles/styles';
@import 'themed-styles/dark-styles';
@import 'main-styles/extensions/extension-fullcalendar';
@import 'themed-styles/extensions/extension-fullcalendar-dark';