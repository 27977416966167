@import '../base/mixins';

/* * {
    outline: none;
} */

* {
    @include scrollbar();
}

h1, h2, h3, h4, h5, h6, p {
    /*     margin: 0;
        padding: 0; */
}

html, body {
    /* height: 100vh;
    max-width: 100vw; */
}

body {
    margin: 0;
}

a {
    cursor: pointer;
}

/* textarea {
    vertical-align: middle;
} */

.rad-grid-container {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
            'mobilemenu'
            'wrapper';

    @media(min-width: $bp-lg) {
        grid-template-columns: auto auto 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            'navbar sidebar wrapper';
    }
}

.rad-grid-mobilemenu {
    grid-area: mobilemenu;
}

.rad-grid-navbar {
    grid-area: navbar;
    height: 100vh;
}

.rad-grid-sidebar {
    grid-area: sidebar;
    height: 100vh;
}

.rad-grid-wrapper {
    grid-area: wrapper;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
            'helper-topbar'
            'content'
            'bottombar';

    @media(min-width: $bp-lg) {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
                'helper-topbar'
                'content'
                'bottombar';
    }
}

.rad-grid-content {
    grid-area: content;
    overflow-x: auto;
}

.rad-grid-helper-topbar {
    grid-area: helper-topbar;
}

.rad-grid-bottombar {
    grid-area: bottombar;
    position: sticky;
    bottom: 0;
}

.rad-background {
    position: fixed;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    background-color: var(--surface-a);
    background-image: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.rad-ui-blocker {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: p-component-overlay-enter-animation 150ms forwards;
    display: none;
    z-index: 100000000;
    backdrop-filter: blur($backdropFilterBlur);

    &-display {
        display: block;
    }

    p-progressspinner {
        position: sticky;
        top: 50vh;
        left: 50vw;
    }
}

.rad-panel-primary {
    background: var(--primary)
}

.rad-label-shortener {
    max-width: calc(100% - $buttonIconOnlyWidth);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
}

.rad-checkbox-label {
    word-break: break-word;
}

@include generate-color-classes('rad-background-color', 'background-color', 1);

.rad-scrollbar {
    @include scrollbar();
}

@keyframes tooltip-slide {
    0% {
        opacity: 0;
        transform: translate(-50%, -30%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

.rad-table-header-cell {
    transition: transform $transitionDuration;

    &.rad-drag-over + .rad-table-header-cell {
        transform: translateX(2rem);
    }

    &.rad-drag-over:not(:has(+ .rad-table-header-cell)) {
        transform: translateX(-2rem);
    }
}

.rad-column-selector-row {
    margin-top: 1px !important;
    margin-left: 1px !important;
}

.rad-column-selector-col {
    min-height: 6rem;
    max-height: 6rem;
    align-content: center;
    margin-left: -1px !important;
    margin-top: -1px !important;
    border: $panelContentBorder;
}

.rad-file-dropzone {
    color: $primaryColor;
    border: 1px solid;
    border-radius: $borderRadius;
    height: 300px;
    max-height: 50vh;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    transition: $formElementTransition;
    transition-duration: $transitionDuration;
    gap: 1rem;
    padding: $accordionContentPadding;

    &-disabled {
        opacity: $disabledOpacity;
        cursor: no-drop;
    }

    .rad-file-dropzone-icon-wrapper {
        color: $primaryColor;
        padding: 2rem;
        border: 1px solid $primaryColor;
        border-radius: 50%;
        transition: $formElementTransition;
        transition-duration: $transitionDuration;
    }

    &-hover {
        color: $buttonHoverBg;
        border-color: $buttonHoverBg !important;

        .rad-file-dropzone-icon-wrapper {
            color: $buttonHoverBg;
            border-color: $buttonHoverBg !important;
        }
    }
}

.rad-animate {
    transition: all $transitionDuration
}

.rad-recording-indicator {
    color: red;
    animation: rad-blink 1s infinite;
}

.rad-disable-player {
    pointer-events: none;
    opacity: 0.5;
}

@keyframes rad-blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 1;
    }
}

@include breakpoints-classes('');

@each $pre, $breakpoint in $belowBreakpoints {
    @media(max-width: $breakpoint) {
        @include breakpoints-classes($pre);
    }
}

@each $pre, $breakpoint in $breakpoints {
    @media(min-width: $breakpoint) {
        @include breakpoints-classes($pre);
    }
}

.rad-bg-success {
    background-color: $successButtonBg;
}

.rad-bg-info {
    background-color: $infoButtonBg;
}

.rad-bg-warning {
    background-color: $warningButtonBg;
}

.rad-bg-danger {
    background-color: $dangerButtonBg;
}

/* .fc-daygrid {
    .fc-daygrid-dot-event {
        .fc-daygrid-event-dot {
            border-color: red;
        }
    }

    .fc-h-event {
        backgorund-color: red;
        {
            .fc-event-main {
                color: red
            }
        }
    }
}

.fc-timegrid {
    .fc-v-event, .fc-h-event {
        border-color: red;

        .fc-event-main {
            color: red
        }
    }
}

.fc-list {
    .fc-list-event-dot {
        border-color: red;
    }
} */

/*
.content {
    height: 100% !important;
}
.fc-col-header, .fc-timegrid-body {
    width: 100% !important;
    height: 100% !important;
}
.fc-scrollgrid-sync-table {
    width: 100% !important;
    height: 100% !important;
}
.fc-daygrid-body {
    width: 100% !important;
    height: 100% !important;
}
.fc-daygrid-body-balanced {
    width: 100% !important;
    height: 100% !important;
}


full-calendar {
    table {
        width: 100% !important;
        height: 100% !important;
    }
}*/

.rad-z-index-0 {
    z-index: $zIndex0;
}

.rad-z-index-1 {
    z-index: $zIndex1;
}

.rad-z-index-2 {
    z-index: $zIndex2;
}

.rad-z-index-3 {
    z-index: $zIndex3;
}

.rad-z-index-4 {
    z-index: $zIndex4;
}

.rad-z-index-5 {
    z-index: $zIndex5;
}

.rad-tests {
    td {

    }
}

.rad-test-h {
    background: lightgray;
}

.rad-test-header {
    font-size: 18px;
    font-weight: bold;
}

.rad-test-value {
    font-size: 16px;
    font-weight: normal;
}

.rad-tests-id {
    width: 300px;
}

.rad-tests-idstep-id {
    width: 200px;
}

.rad-tests-idstep-step {
    width: 10px;
}

.rad-tests-description {
    width: 500px;
}

.rad-tests-title {
    font-size: 22px;
    font-weight: bold;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.rad-sla-50 {
    background-color: var(--yellow-300) !important;
    color: black;
}

.rad-sla-60 {
    background-color: var(--yellow-500) !important;
    color: black;
}

.rad-sla-70 {
    background-color: var(--orange-400) !important;
}

.rad-sla-80 {
    background-color: var(--orange-500) !important;
}

.rad-sla-90 {
    background-color: var(--red-500) !important;
}

.rad-sla-100 {
    background-color: var(--red-700) !important;
    color: white;
}

.rad-frozen-column {
    position: sticky;
    left: 0;
    z-index: 1;
    backdrop-filter: blur(100px);
}

.rad-tree-toggler {
    cursor: pointer;
    @include action-icon();
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-left: $inlineSpacing;

    &:hover {
        background-color: $highlightBg;
    }
}

.rad-checkbox-node {
    height: $actionIconHeight;
}