:root {
  @if variable-exists(colors) {
    @each $name, $color in $colors {
        @for $i from 0 through 5 {
            @if ($i == 0) {
                --#{$name}-50:#{tint($color, (5 - $i) * 19%)};
            }
            @else {
                --#{$name}-#{$i * 100}:#{tint($color, (5 - $i) * 19%)};
            }
        }

        @for $i from 1 through 8 {
            $modifier: 15%;
            $saturate: 5%;
            @if ($i > 4) {
                $modifier: 13.5%;
            }

            @if ($i > 5) {
                $modifier: 12.5%;
                $saturate: 18%;
            }

            @if ($i > 6) {
                $modifier: 11.5%;
                $saturate: 10%;
            }

            @if ($i > 7) {
                $modifier: 10.75%;
            }

            $shade: $i * $modifier;
            @if ($shade > 100%) {
                $shade: 100%;
            }

            $shadedColor: shade($color, $shade);

            @if ($i < 5) {
                --#{$name}-#{($i + 5) * 100}:#{$shadedColor};
            } @else {
                --#{$name}-#{($i + 5) * 100}:#{saturate($shadedColor, $saturate)};
            }
        }
    }
  }
}
