@import 'variables/variables';
@import 'variables/variables-bootstrap';
@import 'base/functions';

@mixin make-container($padding-x: $container-padding-x) {
    width: 100%;
    padding-right: $padding-x;
    padding-left: $padding-x;
    margin-right: auto;
    margin-left: auto;
}

$colorsMap: (
        blue,
        green,
        yellow,
        cyan,
        pink,
        indigo,
        teal,
        orange,
        bluegray,
        purple,
        red,
        primary,
        secondary,
        help
);

@mixin breakpoints-classes($breakpoint) {
    .#{getBreakpointClassNameSplit('container', 'pure', $breakpoint)} {
        @include make-container(0rem);
    }

    @each $pre, $value in $steps-values {
        .#{getBreakpointClassNameSplit('container-fluid', #{$pre}, $breakpoint)} {
            @include make-container(#{calc($value / 2)}rem);
        }
    }

    @for $i from 0 through 99 {
        .#{getBreakpointClassNameSplit('order', #{$i}, $breakpoint)} {
            order: #{$i} !important;
        }
    }

    @for $i from 1 through 99 {
        .#{getBreakpointClassNameSplit('n-order', #{$i}, $breakpoint)} {
            order: -#{$i} !important;
        }
    }

    .#{getBreakpointClassNameSplit('w', '0', $breakpoint)} {
        width: 0 !important;
    }

    .#{getBreakpointClassNameSplit('w', '100', $breakpoint)} {
        width: 100% !important;
    }

    .#{getBreakpointClassNameSplit('w', 'auto', $breakpoint)} {
        width: auto !important;
    }

    .#{getBreakpointClassNameSplit('w' , 'min-content', $breakpoint)} {
        width: min-content !important;
    }

    .#{getBreakpointClassNameSplit('w' , 'fit-content', $breakpoint)} {
        width: fit-content !important;
    }

    .#{getBreakpointClassNameSplit('w' , 'max-content', $breakpoint)} {
        width: max-content !important;
    }

    .#{getBreakpointClassNameSplit('h', '0', $breakpoint)} {
        height: 0 !important;
    }

    .#{getBreakpointClassNameSplit('h', 'auto', $breakpoint)} {
        height: auto !important;
    }

    .#{getBreakpointClassNameSplit('h' , 'min-content', $breakpoint)} {
        height: min-content !important;
    }

    .#{getBreakpointClassNameSplit('h' , 'max-content', $breakpoint)} {
        height: max-content !important;
    }

    .#{getBreakpointClassNameSplit('h' , '0', $breakpoint)} {
        height: 0 !important;
    }

    .#{getBreakpointClassNameSplit('h', '80vh', $breakpoint)} {
        height: 80vh !important;
    }

    .#{getBreakpointClassNameSplit('h', '100vh', $breakpoint)} {
        height: 100vh !important;
    }

    .#{getBreakpointClassNameSplit('min-h', '100vh', $breakpoint)} {
        min-height: 100vh !important;
    }

    .#{getBreakpointClassNameSplit('max-h', '0', $breakpoint)} {
        max-height: 0 !important;
    }

    .#{getBreakpointClassNameSplit('max-h', '100vh', $breakpoint)} {
        max-height: 100vh !important;
    }

    .#{getBreakpointClassNameSplit('max-h', '100', $breakpoint)} {
        max-height: 100% !important;
    }

    .#{getBreakpointClassNameSplit('mt-ng', '100', $breakpoint)} {
        margin-top: -100% !important;
    }

    .#{getBreakpointClassNameSplit('min-w', 'fit-content', $breakpoint)} {
        min-width: fit-content !important;
    }

    .#{getBreakpointClassNameSplit('max-w', '0', $breakpoint)} {
        max-width: 0 !important;
    }

    .#{getBreakpointClassNameSplit('max-w', '100vw', $breakpoint)} {
        max-width: 100vw !important;
    }

    .#{getBreakpointClassNameSplit('max-w', 'fit-content', $breakpoint)} {
        max-width: fit-content !important;
    }

    .#{getBreakpointClassNameSplit('max-w', 'max-content', $breakpoint)} {
        max-width: max-content !important;
    }

    .#{getBreakpointClassNameSplit('position', 'sticky', $breakpoint)} {
        position: -webkit-sticky !important;
        position: sticky !important;
    }

    .#{getBreakpointClassNameSplit('top', '0', $breakpoint)} {
        top: 0 !important;
    }

    .#{getBreakpointClassNameSplit('bottom', '0', $breakpoint)} {
        bottom: 0 !important;
    }

    .#{getBreakpointClassNameSplit('rad-scrollable-content-wrapper', '', $breakpoint)} {
        height: 100%;
        display: flex;
        flex-direction: column;

        .rad-scrollable-content {
            overflow-y: auto;
            flex: 1;
        }
    }

    .#{getBreakpointClassNameSplit('max-w', '300px', $breakpoint)} {
        max-width: 300px !important;
    }

    .#{getBreakpointClassNameSplit('max-w', '400px', $breakpoint)} {
        max-width: 400px !important;
    }

    .#{getBreakpointClassNameSplit('max-w', '600px', $breakpoint)} {
        max-width: 600px !important;
    }

    .#{getBreakpointClassNameSplit('d', 'grid', $breakpoint)} {
        display: grid !important;
    }

    .#{getBreakpointClassNameSplit('d', 'none', $breakpoint)} {
        display: none !important;
    }

    .#{getBreakpointClassNameSplit('overflow-y', 'auto', $breakpoint)} {
        overflow-y: auto !important;
    }

    .#{getBreakpointClassNameSplit('overflow-x', 'auto', $breakpoint)} {
        overflow-x: auto !important;
    }

    .#{getBreakpointClassNameSplit('overflow', 'auto', $breakpoint)} {
        overflow: auto !important;
    }

    .#{getBreakpointClassNameSplit('rad-border', 'primary', $breakpoint)} {
        border: 1px solid $primaryColor;
    }

    .#{getBreakpointClassNameSplit('rad-border-color', 'primary', $breakpoint)} {
        border-color: $primaryColor !important;
    }

    .#{getBreakpointClassNameSplit('rad-border', 'primary-hover', $breakpoint)} {
        border: 1px solid $buttonHoverBg;
    }

    .#{getBreakpointClassNameSplit('rad-border-color', 'primary-hover', $breakpoint)} {
        border-color: $buttonHoverBg !important;
    }

    .#{getBreakpointClassNameSplit('border-radius', '50', $breakpoint)} {
        border-radius: 50% !important;
    }
}

@mixin width-child-classes($parent, $child, $class, $value, $unit, $breakpoint) {
    #{$parent}.#{getBreakpointClassNameSplit($class, $value, $breakpoint)} #{$child} {
        width: #{$value + $unit} !important;
    }
}

@mixin generate-color-class($className, $property, $color) {
    .#{unquote($className)} {
        #{$property}: #{$color}
    }
}

@mixin generate-color-classes($className, $property, $invert) {
    $base: 50;

    @each $name, $color in $colors {
        @for $i from 0 through 13 {
            $value: ($base * $i * 2);
            $classNameValue: $value;

            @if ($invert == -1) {
                $value: 1300 - ($base * $i * 2);
            }

            @if ($i == 0) {
                $value: $base;
                $classNameValue: $value;

                @if ($invert == -1) {
                    $value: 1300;
                }
            }

            $newValue: $name + '-' + $value;
            $newClassNameValue: $name + '-' + $classNameValue;
            @include generate-color-class($className + '-' + $newClassNameValue, $property, var(--#{$newValue}));
        }
    }
}

@mixin scrollbar() {
    &::-webkit-scrollbar {
        width: 16px;
        height: 16px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $primaryColor;
        border: 4px solid transparent;
        border-radius: 8px;
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}