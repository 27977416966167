@import 'base/mixins';

.w-100 {
    input,
    .p-calendar,
    .p-dropdown,
    .p-password,
    p-editor,
    .p-inputwrapper,
    .p-inputwrapper > .p-inputnumber,
    .p-inputtextarea,
    app-tags-selector {
        width: 100% !important;
    }

    .p-inputnumber {
        input {
            width: 100% !important;
        }
    }
}

.p-calendar {
    .p-button {
        background: $panelContentBg;
    }
}

.p-colorpicker-color {
    background: transparent url("../../../images/primeng/color.png") no-repeat left top !important;
}

.p-colorpicker-panel .p-colorpicker-hue {
    background: transparent url("../../../images/primeng/hue.png") no-repeat left top !important;
}

.p-editor-content {
    .ql-editor {
        min-height: 300px;
        height: auto;
        max-height: 60vh;
    }
}

.p-divider-solid.p-divider-horizontal:before {
    border-top-style: solid !important;
}

.p-multiselect {
    .p-multiselect-label {
        width: 100% !important;
    }

    .p-tag {
        cursor: default;

        .pi {
            cursor: pointer;
        }
    }
}

.p-datatable {
    .p-sortable-column {
        white-space: nowrap;
    }

    .p-datatable-wrapper {
        .p-datatable-thead > tr > th {
            &:first-of-type {
                border-top-left-radius: $borderRadius;
            }

            &:last-of-type {
                border-top-right-radius: $borderRadius;
            }
        }
    }

    &.p-datatable-hoverable-rows {
        .p-datatable-tbody > tr {
            &:not(.p-highlight):hover {
                cursor: pointer;
            }
        }
    }

    .p-datatable-tbody {
        > tr:last-of-type {
            > td {
                border-bottom: none;

                &:first-of-type {
                    border-bottom-left-radius: $borderRadius;
                }

                &:last-of-type {
                    border-bottom-right-radius: $borderRadius;
                }
            }
        }
    }

}

.p-paginator {
    .p-paginator-pages {
        .p-paginator-page {
            &.p-highlight {
                color: $paginatorElementIconColor;
            }
        }
    }
}

/* p-progressSpinner {
    display: flex;

    &.sm {
        padding: 10px 0;

        .p-progress-spinner {
            height: 36px;
            width: 36px
        }
    }
} */

.p-progress-spinner {
    max-width: 94%;

    &.sm {
        height: 30px;
        width: 30px
    }
}

p-editor.ng-dirty.ng-invalid > .p-editor-container {
    .p-editor-toolbar {
        border-top-color: $inputErrorBorderColor;
        border-left-color: $inputErrorBorderColor;
        border-right-color: $inputErrorBorderColor;
    }

    .p-editor-content {
        border-bottom-color: $inputErrorBorderColor;
        border-left-color: $inputErrorBorderColor;
        border-right-color: $inputErrorBorderColor;
    }
}

p-button {
    display: block;
}

.p-button {
    &.p-button-outlined {
        .p-badge:not(.p-badge-secondary, .p-badge-contrast, .p-badge-success, .p-badge-info, .p-badge-warning, .p-badge-danger) {
            background: $badgeBg;
            color: $badgeTextColor;
        }
    }

    &.p-button-icon-only {
        &.p-button-sm {
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
        }
    }

    &.p-button-link {
        color: $buttonBg;
        padding: 0;
        line-break: anywhere;

        &:not(:disabled) {
            &:hover .p-button-label {
                color: $linkButtonColor;

                .rad-breadcrumb-non-clickable & {
                    color: $buttonBg;
                    cursor: default !important;
                    text-decoration: none !important;
                }
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    app-google-icon:not(.p-button-icon-right) {
        @extend .p-button-icon-left;
    }
}

app-google-icon,
app-navbar-item,
app-additional-element,
.rad-grid-mobilemenu,
.p-button {
    .position-relative {
        .p-badge {
            position: absolute;
            top: 20%;
            right: 10%;

            &:not(.p-badge-dot) {
                position: absolute;
                bottom: 50%;
                left: 50%;
            }
        }
    }
}

app-mobile-menu-item {
    .position-relative {
        .p-badge.p-badge-dot {
            position: absolute;
            top: 20%;
            right: 3%;
        }
    }
}

.p-badge-dot {
    width: .5rem;
    min-width: .5rem;
    height: .5rem;
    border-radius: 50%;
    padding: 0;
}

.p-error {
    color: $errorColor !important;
}

.p-input-icon-left > app-google-icon {
    position: absolute;
    top: nth($inputPadding, 1);
    left: nth($inputPadding, 2);
    color: $inputIconColor;
}

.p-input-icon-right > app-google-icon,
p-inputIcon:has(~ .p-inputtext),
p-inputIcon:has(~ app-google-icon ~ .p-inputtext),
app-google-icon:has(~ .p-inputtext),
app-google-icon:has(~ app-google-icon ~ .p-inputtext) {
    position: absolute;
    top: nth($inputPadding, 1);
    right: nth($inputPadding, 2);
    color: $inputIconColor;
}

p-inputIcon:has(~ .p-inputtext),
p-inputIcon:has(~ app-google-icon ~ .p-inputtext) {
    top: 50%;
    margin-top: -.5rem;
}

app-google-icon:has(~ app-google-icon ~ .p-inputtext),
p-inputIcon:has(~ app-google-icon ~ .p-inputtext) {
    right: nth($inputPadding, 2) * 3 + $primeIconFontSize;
}

app-google-icon ~ .p-inputtext {
    padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
}

app-google-icon ~ app-google-icon ~ .p-inputtext,
.pi ~ app-google-icon ~ .p-inputtext {
    padding-right: nth($inputPadding, 2) * 4 + $primeIconFontSize;
}

.p-panel:not(.p-panel-expanded) {
    .p-panel-header {
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
    }
}

.p-dialog {
    max-height: 100vh;
    max-width: 100vw;

    .p-dialog-header {
        align-items: start;

        .p-dialog-header-icon {
            padding: 0 !important;
        }
    }
}

.p-component-overlay {
    backdrop-filter: blur($backdropFilterBlur);
}

.p-contextmenu {
    z-index: $zIndex5;
}

.p-datepicker-touch-ui {
    min-width: unset !important;
}

.p-accordion {
    p-accordiontab {
        &:only-child {
            .p-accordion-tab {
                margin-bottom: unset;
            }
        }
    }
}

p-table td {
    p-button, .p-button {
        &:not(.p-button-icon-only) {
            width: 200px !important;
        }

        &:has(.p-button-icon-only) {
            text-align: center !important;
        }
    }

    & > app-google-icon > span {
        margin-left: auto;
        margin-right: auto;
    }
}

.rad-dialog-content:has(* > app-form:first-child),
.rad-dialog-content:has(> app-form:first-child),
.p-sidebar-content:has(> app-form:first-child),
.p-card-content:not(:has(.row > div > app-section-wrapper)):has(* > app-form:first-child),
.p-card-content:has(> app-form:first-child),
.p-card-content:has(> app-table-filter:first-child),
.p-accordion-content:not(:has(.row > div > app-section-wrapper)):has(* > app-form:first-child),
.p-accordion-content:has(> app-form:first-child),
.p-accordion-content:has(> app-table-filter:first-child),
.p-panel-content:not(:has(.row > div > app-section-wrapper)):has(* > app-form:first-child),
.p-panel-content:has(> app-form:first-child),
.p-panel-content:has(> app-table-filter:first-child) {
    padding-top: 2.5rem !important;
}

app-helper-topbar {
    .p-tabview {
        .p-tabview-nav-content {
            overflow: unset;

            .p-tabview-nav {
                background: transparent;
                border: none;

                li, li.p-highlight {
                    .p-tabview-nav-link {
                        background: transparent;
                        padding: .25rem;
                        margin: 0;
                        border: none;
                    }
                }
            }
        }

        .p-tabview-panels {
            display: none;
        }
    }
}

.rad-navbar {
    p-button + .p-button-label {
        margin-left: .5rem;
    }

    .p-button-label {
        opacity: 1;
        white-space: break-spaces;
        text-align: left;
    }
}

app-navbar, app-bottombar, app-helper-topbar {
    .p-button-outlined {
        border-color: rgba(255, 255, 255, 0.16);
        background-color: rgba(255, 255, 255, 0.04);
        color: $shade000;
    }
}

.rad-navbar-hover-detector { /* :not(:hover) */
    .p-button-label {
        width: 0;
        opacity: 0;
        white-space: nowrap;
        overflow-x: hidden;
        margin-left: 0 !important;
    }
}

app-sidebar {
    .p-button {
        color: $shade000;

        &.p-button-outlined {
            border-color: var(--primary-200);
        }
    }

    p-button + .p-button-label {
        margin-left: .5rem;
    }

    .p-button-label {
        white-space: break-spaces;
        text-align: left;
    }
}

.rad-mobile-menu {
    .p-button {
        justify-content: end;
    }

    .p-button-label {
        white-space: break-spaces;
        text-align: right;
        max-width: fit-content;
    }
}

.rad-card-notification {
    .p-card-header {
        padding: .5rem !important;
        padding-bottom: 0 !important;
    }

    .p-card-content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}

.rad-section-wrapper-center-header {
    .p-card-title, .p-panel-title {
        text-align: center;
    }
}

.p-tag.p-tag-icon-right {
    .pi {
        margin-left: $inlineSpacing;
        order: 1;
    }
}

.p-toast {
    z-index: 100000001 !important;
}

app-tags-selector {
    .rad-p-input-container {
        min-height: calc($tagPaddingY * 2 + $badgeFontSize + $inputPaddingY * 2);

        font-family: var(--font-family);
        font-feature-settings: var(--font-feature-settings, normal);
        font-size: $inputTextFontSize;
        color: $inputTextColor;
        background: $inputBg;
        padding: $inputPadding;
        border: $inputBorder;
        transition: $formElementTransition;
        appearance: none;
        border-radius: $borderRadius;

        &:hover {
            border-color: $inputHoverBorderColor;
        }
    }

    &.ng-dirty.ng-invalid {
        .rad-p-input-container {
            @include invalid-input();
        }
    }
}

.p-submenu-list {
    max-height: 50vh;
}

:root {
    --success: #{$successButtonBg};
    --info: #{$infoButtonBg};
    --warning: #{$warningButtonBg};
    --error: #{$dangerButtonBg};
}